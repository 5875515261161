export default[ 
    {
      path: '/manajemen_opd',
      name: '/manajemen_opd',
      component: () => import('@/views/setup/manajemen_opd/index.vue'),
      meta: {
        pageTitle: 'Setup ',
        breadcrumb: [ 
          {
            text: 'Setup',
            to : '/manajemen_opd'
          },
          {
            text: 'Manajemen OPD', 
            active: true,
          },
        ],
      },
    },
    {
        path: '/data_rekanan',
        name: '/data_rekanan',
        component: () => import('@/views/setup/data_rekanan/index.vue'),
        meta: {
          pageTitle: 'Setup ',
          breadcrumb: [ 
            {
              text: 'Setup',
              to : '/data_rekanan'
            },
            {
              text: 'Data Rekanana', 
              active: true,
            },
          ],
        },
      }, 
       
]