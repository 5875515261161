import Vue from 'vue'
import VueRouter from 'vue-router'
import acl from './routers/acl'
import error from './routers/error'
import home from './routers/home'
import profile from './routers/profile' 
import setup from './modul/setup'
import admin_opd from './modul/admin_opd'
import laporan from './modul/laporan'
import input from './modul/input'
import pagu from './modul/pagu'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    { path: '/', redirect: { name: 'login' } },
    ...acl,
    ...error,
    ...home,
    ...profile,  
    ...setup, 
    ...admin_opd,
    ...laporan,
    ...input,
    ...pagu
  ],
}) 
// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})



export default router
