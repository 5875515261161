export default [{
        path: '/input_kegiatan',
        name: '/input_kegiatan',
        component: () => import('@/views/admin_opd/input_kegiatan/index.vue'),
        meta: {
            pageTitle: 'Admin OPD ',
            breadcrumb: [{
                    text: 'Admin OPD',
                    to: '/input_kegiatan'
                },
                {
                    text: 'Input Kegiatan',
                    active: true,
                },
            ],
        },
    }, {
        path: '/input_projek',
        name: '/input_projek',
        component: () => import('@/views/admin_opd/input_projek/index.vue'),
        meta: {
            pageTitle: 'Admin OPD ',
            breadcrumb: [{
                    text: 'Admin OPD',
                    to: '/input_projek'
                },
                {
                    text: 'Input Projek',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/realisasi_projek',
        name: '/realisasi_projek',
        component: () => import('@/views/admin_opd/realisasi_projek/index.vue'),
        meta: {
            pageTitle: 'Admin OPD ',
            breadcrumb: [{
                    text: 'Admin OPD',
                    to: '/realisasi_projek'
                },
                {
                    text: 'Realisai Projek',
                    active: true,
                },
            ],
        },
    },


]