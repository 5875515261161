export default[ 
    {
      path: '/pagu_pendapatan',
      name: '/pagu_pendapatan',
      component: () => import('@/views/pagu/pendapatan/index.vue'),
      meta: {
        pageTitle: 'Pagu ',
        breadcrumb: [ 
          {
            text: 'Pagu',
            to : '/pagu_pendapatan'
          },
          {
            text: 'Pendapatan', 
            active: true,
          },
        ],
      },
    },
    {
        path: '/pagu_belanja',
        name: '/pagu_belanja',
        component: () => import('@/views/pagu/belanja/index.vue'),
        meta: {
          pageTitle: 'Pagu ',
          breadcrumb: [ 
            {
              text: 'Pagu',
              to : '/pagu_belanja'
            },
            {
              text: 'Belanja', 
              active: true,
            },
          ],
        },
      }, 
      {
        path: '/belanja_per_objek',
        name: '/belanja_per_objek',
        component: () => import('@/views/objek_belanja/index.vue'),
        meta: {
          pageTitle: 'Objek ',
          breadcrumb: [ 
            {
              text: 'Pagu',
              to : '/belanja_per_objek'
            },
            {
              text: 'Belanja', 
              active: true,
            },
          ],
        },
      }, 
      {
        path: '/pendapatan_per_objek',
        name: '/pendapatan_per_objek',
        component: () => import('@/views/objek_pendapatan/index.vue'),
        meta: {
          pageTitle: 'Objek ',
          breadcrumb: [ 
            {
              text: 'Pagu',
              to : '/pendapatan_per_objek'
            },
            {
              text: 'Pendapatan', 
              active: true,
            },
          ],
        },
      }, {
        path: '/pengajuan_dana',
        name: '/pengajuan_dana',
        component: () => import('@/views/pengajuan_dana/index.vue'),
        meta: {
          pageTitle: 'Pengajuan Dana ',
          breadcrumb: [ 
            {
              text: 'Pengajaun Dana',
              to : '/pengajuan_dana'
            },
            
          ],
        },
      }, 
      {
        path: '/input_pendapatan',
        name: '/input_pendapatan',
        component: () => import('@/views/input_pendapatan/index.vue'),
        meta: {
          pageTitle: 'Input Data Pendapatan ',
          breadcrumb: [ 
            {
              text: 'Input Data Pendapatan',
              to : '/input_pendapatan'
            },
            
          ],
        },
      }, 
       
]