export default [
    {
        path: '/laporan_paket',
        name: '/laporan_paket',
        component: () => import('@/views/laporan/laporan_paket/index.vue'),
        meta: {
            pageTitle: 'Laporan ',
            breadcrumb: [{
                    text: 'Laporan',
                    to: '/laporan_paket'
                }, {
                    text: 'Lapoaran Paket',
                    to: '/laporan_paket',
                    active: true,
                }, 
            ],
        },
    },
    {
        path: '/laporan_paket_berdasarkan_sumber_dana',
        name: '/laporan_paket_berdasarkan_sumber_dana',
        component: () => import('@/views/laporan/laporan_paket_berdasarkan_sumber_dana/index.vue'),
        meta: {
            pageTitle: 'Laporan ',
            breadcrumb: [{
                    text: 'Laporan',
                    to: '/laporan_paket_berdasarkan_sumber_dana'
                }, {
                    text: 'Lapoaran Paket Berdasarkan Sumber Dana',
                    to: '/laporan_paket_berdasarkan_sumber_dana',
                    active: true,
                }, 
            ],
        },
    },
    {
        path: '/laporan_paket_kdp',
        name: '/laporan_paket_kdp',
        component: () => import('@/views/laporan/laporan_paket_kdp/index.vue'),
        meta: {
            pageTitle: 'Laporan ',
            breadcrumb: [{
                    text: 'Laporan',
                    to: '/laporan_paket_kdp'
                }, {
                    text: 'Lapoaran Paket KDP',
                    to: '/laporan_paket_kdp',
                    active: true,
                }, 
            ],
        },
    },
    {
        path: '/laporan_paket_sesuai_lokasi_kecamatan',
        name: '/laporan_paket_sesuai_lokasi_kecamatan',
        component: () => import('@/views/laporan/laporan_paket_sesuai_lokasi_kecamatan/index.vue'),
        meta: {
            pageTitle: 'Laporan ',
            breadcrumb: [{
                    text: 'Laporan',
                    to: '/laporan_paket_sesuai_lokasi_kecamatan'
                }, {
                    text: 'Lapoaran Paket Sesuai Lokasi Kecamatan',
                    to: '/laporan_paket_sesuai_lokasi_kecamatan',
                    active: true,
                }, 
            ],
        },
    },
    {
        path: '/laporan_pendapatan',
        name: '/laporan_pendapatan',
        component: () => import('@/views/laporan/laporan_pendapatan/index.vue'),
        meta: {
            pageTitle: 'Laporan ',
            breadcrumb: [{
                    text: 'Laporan',
                    to: '/laporan_pendapatan'
                }, {
                    text: 'Lapoaran Pendapatan ',
                    to: '/laporan_pendapatan',
                    active: true,
                }, 
            ],
        },
    },
    {
        path: '/laporan_realisasi_anggaran',
        name: '/laporan_realisasi_anggaran',
        component: () => import('@/views/laporan/laporan_realisasi_anggaran/index.vue'),
        meta: {
            pageTitle: 'Laporan ',
            breadcrumb: [{
                    text: 'Laporan',
                    to: '/laporan_realisasi_anggaran'
                }, {
                    text: 'Lapoaran Realisasi Anggaran',
                    to: '/laporan_realisasi_anggaran',
                    active: true,
                }, 
            ],
        },
    },
    {
        path: '/laporan_realisasi_kontrak',
        name: '/laporan_realisasi_kontrak',
        component: () => import('@/views/laporan/laporan_realisasi_kontrak/index.vue'),
        meta: {
            pageTitle: 'Laporan ',
            breadcrumb: [{
                    text: 'Laporan',
                    to: '/laporan_realisasi_kontrak'
                }, {
                    text: 'Lapoaran Realisasi Kontrak',
                    to: '/laporan_realisasi_kontrak',
                    active: true,
                }, 
            ],
        },
    },
    {
        path: '/laporan_realisasi_paket',
        name: '/laporan_realisasi_paket',
        component: () => import('@/views/laporan/laporan_realisasi_paket/index.vue'),
        meta: {
            pageTitle: 'Laporan ',
            breadcrumb: [{
                    text: 'Laporan',
                    to: '/laporan_realisasi_paket'
                }, {
                    text: 'Lapoaran Realisasi Paket',
                    to: '/laporan_realisasi_paket',
                    active: true,
                }, 
            ],
        },
    },
    {
        path: '/rekap_belanja',
        name: '/rekap_belanja',
        component: () => import('@/views/laporan/rekap_belanja/index.vue'),
        meta: {
            pageTitle: 'Laporan ',
            breadcrumb: [{
                    text: 'Laporan',
                    to: '/rekap_belanja'
                }, {
                    text: 'Rekap Belanda SKPD',
                    to: '/rekap_belanja',
                    active: true,
                }, 
            ],
        },
    },
    
    

]