export default [
    {
    path: '/input_data_paket',
    name: '/input_data_paket',
    component: () => import('@/views/input/data_paket/index.vue'),
    meta: {
        pageTitle: 'Input  ',
        breadcrumb: [{
                text: 'Input',
                to: '/input_data_paket'
            },
            {
                text: 'Input Data Paket',
                active: true,
            },
        ],
    },
}, {
    path: '/input_data_kontrak',
    name: '/input_data_kontrak',
    component: () => import('@/views/input/data_kontrak/index.vue'),
    meta: {
        pageTitle: 'Input  ',
        breadcrumb: [{
                text: 'Input',
                to: '/input_data_kontrak'
            },
            {
                text: 'Input Data Kontrak',
                active: true,
            },
        ],
    },
}, {
    path: '/input_permintaan_dana',
    name: '/input_permintaan_dana',
    component: () => import('@/views/input/data_permintaan_dana/index.vue'),
    meta: {
        pageTitle: 'Input  ',
        breadcrumb: [{
                text: 'Input',
                to: '/input_permintaan_dana'
            },
            {
                text: 'Input Data Permintaan Dana',
                active: true,
            },
        ],
    },
}, {
    path: '/input_data_pendapatan',
    name: '/input_data_pendapatan',
    component: () => import('@/views/input/data_pendapatan/index.vue'),
    meta: {
        pageTitle: 'Input  ',
        breadcrumb: [{
                text: 'Input',
                to: '/input_data_pendapatan'
            },
            {
                text: 'Input Data Pendapatan',
                active: true,
            },
        ],
    },
}, 
]